import React from 'react'
import { Link } from 'react-router-dom'

export default function Home() {
  return (
    <div className='wrapper'>
      {/* 1 */}
      <div className='relative'>
        <img src={require('assets/images/home-background.png')} alt='' className='w-full' />

        <div className='container mx-auto'>
          <div className='absolute flex flex-col gap-10 -translate-x-1/2 -translate-y-1/2 max-sm:w-full top-1/2 left-1/2 md:translate-x-1/4 xl:translate-x-1/2 max-sm:items-center md:gap-20 lg:gap-30 xl:px-20'>
            <p className='text-title3-bold sm:text-title2-bold xl:text-title1-bold text-sk-primary-blue'>
              친환경 3D 프린팅의 길잡이 <br className='hidden sm:block' />
              페타멘트
            </p>
            <p className='hidden sm:block text-body1-medium md:text-h2-medium lg:text-h1-medium text-sk-default'>
              3D 프린터를 환경적으로 이용할 수 있는
              <br />
              페타멘트 솔루션을 확인해보세요.
            </p>
            <div className='flex gap-[15px] lg:gap-[33px]'>
              <Link to={'/service'} className='max-sm:px-12 max-sm:py-10 button1'>
                <p className='text-body3-regular'>서비스 살펴보기</p>
              </Link>
              <Link to={'/content'} className='max-sm:px-12 max-sm:py-10 button2 '>
                <p className='text-body3-regular'>콘텐츠 확인하기</p>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* 친환경 3D 프린팅 솔루션 */}
      <div className='bg-sk-white'>
        <div className='container mx-auto'>
          <div className='px-20 py-[60px]'>
            <div className='flex flex-col items-center justify-center gap-40'>
              <p className='text-title3-bold sm:text-title2-bold lg:text-title1-bold text-sk-primary-blue'>
                친환경 3D 프린팅 솔루션
              </p>
              <p className='text-center text-body1-medium sm:text-h2-medium lg:text-h1-medium text-sk-default'>
                페타멘트는 누구나 쉽게 3D 프린터를 이용할 수 있는 환경을 조성하고&nbsp;
                <br className='hidden sm:block' />
                사용자들이 3D 프린터를 이용하면서 친환경적인 가치를 느낄 수 있도록 하고자 합니다.
              </p>
              <div>
                <img src={require('assets/images/home-image.png')} alt='' />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 임시 이미지 */}
      <div className='bg-sk-primary-white py-[60px] px-[20px]'>
        <div className='container mx-auto'>
          <div className='flex items-center justify-center'>
            <img src={require('assets/images/home-background1.png')} alt='' />
          </div>
        </div>
      </div>

      {/* 페타멘트를 이용하는 이유 */}
      <div className='bg-sk-white'>
        <div className='py-[60px] flex flex-col justify-center items-center gap-40'>
          <p className='text-title3-bold sm:text-title2-bold lg:text-title1-bold text-sk-primary-blue'>
            페타멘트를 이용하는 이유
          </p>
          <div className='container mx-auto'>
            <div className='flex flex-wrap items-center justify-center gap-40 px-20'>
              {/* 1 */}
              <div className='px-20 py-40 bg-sk-primary-white home-shadowBox rounded-10'>
                <div className='flex flex-col gap-40'>
                  <div>
                    <img
                      src={require('assets/images/home-image1.png')}
                      alt=''
                      className='w-[400px] h-[200px] rounded-20'
                    />
                  </div>
                  <div className='flex flex-col items-start gap-8'>
                    <p className='text-sk-primary-blue text-h1-semibold'>
                      친환경적인 프린팅 프로세스
                    </p>
                    <p className='text-sk-primary-gray text-body1-medium'>
                      페트병 재활용 필라멘트를 통해 새로운 환경적 가치를 창출
                    </p>
                  </div>
                </div>
              </div>

              {/* 2 */}
              <div className='px-20 py-40 bg-sk-primary-white home-shadowBox rounded-10'>
                <div className='flex flex-col gap-40'>
                  <div>
                    <img
                      src={require('assets/images/home-image2.png')}
                      alt=''
                      className='w-[400px] h-[200px] rounded-20'
                    />
                  </div>
                  <div className='flex flex-col items-start gap-8'>
                    <p className='text-sk-primary-blue text-h1-semibold'>3D 프린팅 진입장벽 해소</p>
                    <p className='text-sk-primary-gray text-body1-medium'>
                      전문 소프트웨어 사용으로 3D 모델링 단계의 어려움 해소
                    </p>
                  </div>
                </div>
              </div>

              {/* 3 */}
              <div className='px-20 py-40 bg-sk-primary-white home-shadowBox rounded-10'>
                <div className='flex flex-col gap-40'>
                  <div>
                    <img
                      src={require('assets/images/home-image3.png')}
                      alt=''
                      className='w-[400px] h-[200px] rounded-20'
                    />
                  </div>
                  <div className='flex flex-col items-start gap-8'>
                    <p className='text-sk-primary-blue text-h1-semibold'>3D 프린팅 환경 조성 </p>
                    <p className='text-sk-primary-gray text-body1-medium'>
                      교육 프로그램과 콘텐츠로 3D 프린팅 이용자를 지원
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 누구나 쉽게 3D 프린팅을 할 수 있도록 합니다 */}
      <div className='bg-sk-primary-white'>
        <div className='px-20 py-[60px] flex flex-col justify-center items-center gap-40'>
          <p className='text-center text-title3-bold sm:text-title2-bold lg:text-title1-bold text-sk-primary-blue'>
            누구나 쉽게
            <br className='block sm:hidden' /> 3D 프린팅을 할 수 있도록 합니다.
          </p>
          <p className='px-20 text-center text-body1-medium sm:text-h2-medium lg:text-h1-medium text-sk-default'>
            페타멘트 솔루션을 통해 3D 프린터 사용자를 위한 생태계를 조성하고&nbsp;
            <br className='hidden sm:block' />
            3D 프린터를 이용하는 단계들을 지원하고자 합니다.
          </p>
          {/* Responsive - md 이상 */}
          <div className='hidden md:grid grid-cols-2 gap-[70px] '>
            {/* 1 */}
            <div className='relative flex items-center gap-20 '>
              <div className='w-[320px] flex flex-col justify-center gap-8 text-center'>
                <p className='text-h2-semibold xl:text-h1-semibold text-sk-black'>
                  PET병 재활용 필라멘트 모듈
                </p>
                <p className='text-body2-medium xl:text-body1-medium text-sk-black'>‘페타멘트’</p>
              </div>
              <div className='relative shrink-0'>
                <img
                  src={require('assets/images/home-image4.png')}
                  alt=''
                  className='w-[150px] h-[150px] rounded-[10000px] shrink-0'
                />
                {/* 연결선1 */}
                <div className='absolute left-1/2 w-2 h-[70px] bg-sk-primary-blue'></div>
              </div>
              {/* 연결선2 */}
              <div className='absolute right-[-70px] w-[70px] h-2 bg-sk-primary-blue'></div>
            </div>

            {/* 2 */}
            <div className='flex items-center gap-20 '>
              <div className='shrink-0'>
                <img
                  src={require('assets/images/home-image5.png')}
                  alt=''
                  className='w-[150px] h-[150px] rounded-[10000px]'
                />
              </div>
              <div className='w-[320px] flex flex-col justify-center gap-8 text-center'>
                <p className='text-h2-semibold xl:text-h1-semibold text-sk-black'>
                  전용 3D Modeliing Software
                </p>
                <p className='text-body2-medium xl:text-body1-medium text-sk-black'>‘에코블럭’ </p>
              </div>
            </div>

            {/* 3 */}
            <div className='flex items-center gap-20 '>
              <div className='w-[320px] flex flex-col justify-center gap-8 text-center'>
                <p className='text-h2-semibold xl:text-h1-semibold text-sk-black'>
                  온라인 교육 콘텐츠
                </p>
              </div>
              <div className='shrink-0'>
                <img
                  src={require('assets/images/home-image6.png')}
                  alt=''
                  className='w-[150px] h-[150px] rounded-[10000px]'
                />
              </div>
            </div>

            {/* 4 */}
            <div className='relative flex items-center gap-20 '>
              <div className='relative shrink-0'>
                {/* 연결선3 */}
                <div className='absolute top-[-70px] left-1/2 w-2 h-[70px] bg-sk-primary-blue'></div>
                <img
                  src={require('assets/images/home-image7.png')}
                  alt=''
                  className='w-[150px] h-[150px] rounded-[10000px]'
                />
              </div>
              <div className='w-[320px] flex flex-col justify-center gap-8 text-center'>
                <p className='text-h2-semibold xl:text-h1-semibold text-sk-black'>
                  3D 프린터 사용자 대상 <br />
                  친환경 교육/강의
                </p>
              </div>
              {/* 연결선4 */}
              <div className='absolute left-[-70px] w-[70px] h-2 bg-sk-primary-blue'></div>
            </div>
          </div>

          {/* Responsive - md 이하 */}
          <div className='md:hidden flex flex-col gap-[20px] '>
            {/* 1 */}
            <div className='flex items-center gap-20 '>
              <div className='w-[200px] flex flex-col justify-center gap-8 text-center'>
                <p className='text-body1-semibold text-sk-black'>PET병 재활용 필라멘트 모듈</p>
                <p className='text-body2-medium text-sk-black'>‘페타멘트’</p>
              </div>
              <div className='shrink-0'>
                <img
                  src={require('assets/images/home-image4.png')}
                  alt=''
                  className='w-[100px] h-[100px] rounded-[10000px] shrink-0'
                />
              </div>
            </div>

            {/* 2 */}
            <div className='flex items-center gap-20 '>
              <div className='shrink-0'>
                <img
                  src={require('assets/images/home-image5.png')}
                  alt=''
                  className='w-[100px] h-[100px] rounded-[10000px]'
                />
              </div>
              <div className='w-[200px] flex flex-col justify-center gap-8 text-center'>
                <p className='text-body1-semibold text-sk-black'>전용 3D Modeliing Software</p>
                <p className='text-body2-medium text-sk-black'>‘에코블럭’ </p>
              </div>
            </div>

            {/* 3 */}
            <div className='flex items-center gap-20 '>
              <div className='w-[200px] flex flex-col justify-center gap-8 text-center'>
                <p className='text-body1-semibold text-sk-black'>온라인 교육 콘텐츠</p>
              </div>
              <div className='shrink-0'>
                <img
                  src={require('assets/images/home-image6.png')}
                  alt=''
                  className='w-[100px] h-[100px] rounded-[10000px]'
                />
              </div>
            </div>

            {/* 4 */}
            <div className='flex items-center gap-20 '>
              <div className='shrink-0'>
                <img
                  src={require('assets/images/home-image7.png')}
                  alt=''
                  className='w-[100px] h-[100px] rounded-[10000px]'
                />
              </div>
              <div className='flex flex-col justify-center gap-8 text-center'>
                <p className='text-body1-semibold text-sk-black'>
                  3D 프린터 사용자 대상 <br />
                  친환경 교육/강의
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 더 자세한 페타멘트 솔루션 */}
      <div className='bg-sk-primary-gray'>
        <div className='py-[60px] flex flex-col gap-40 justify-center items-center'>
          <p className='text-center text-title3-bold sm:text-title2-bold lg:text-title1-bold text-sk-primary-white'>
            더 자세한
            <br className='block sm:hidden' /> 페타멘트 솔루션을 확인하세요
          </p>
          <Link to={'/service'} className='button2'>
            <p>서비스 살펴보기</p>
          </Link>
        </div>
      </div>
    </div>
  )
}
