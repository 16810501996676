import React from 'react'

export default function Dowonload() {
  return (
    <div className='wrapper'>
      {/* 1 */}
      <div className='relative'>
        <img src={require('assets/images/download-background.png')} alt='' className='w-full' />

        <div className='container mx-auto'>
          <div className='absolute flex flex-col gap-10 px-20 -translate-y-1/2 top-1/2 sm:gap-30 md:px-80'>
            <p className='text-title3-bold sm:text-h2-semibold md:text-h1-semibold lg:text-title3-bold xl:text-title2-bold text-sk-primary-white'>
              <span className='hidden sm:block'>
                쉽고 간편하게 구현하고
                <br />
                누구나 만들 수 있는
                <br />
                3D 모델링 툴<br />
              </span>
              페타먼트 3D Modeler
            </p>
            <div className='button1 mx-28 sm:mx-0'>
              <p>다운로드</p>
            </div>
          </div>
        </div>
      </div>

      {/* 2 */}
      <div className='bg-sk-white'>
        <div className='py-[60px] flex flex-col items-center gap-40'>
          <div className='flex flex-col gap-20 text-center'>
            <p className='text-title3-bold sm:text-title2-bold lg:text-title1-bold text-sk-default'>
              Guidline
            </p>
            <p className='text-body1-medium sm:text-h2-medium text-sk-primary-gray'>
              소프트웨어 가이드라인
            </p>
          </div>
          <div className='bg-sk-primary-white rounded-12'>
            <div className='flex flex-col items-center gap-20 p-20 sm:p-40 sm:flex-row sm:gap-40'>
              <div className='flex flex-col gap-12 max-sm:items-center'>
                <p className='text-title3-bold md:text-title2-bold lg:text-title1-bold text-[#222222]'>
                  3D 모델러
                </p>
                <p className='text-body1-medium lg:text-h2-medium xl:text-h1-medium text-sk-black'>
                  더 직관적이고 재미있는 소프트웨어 활용방법
                </p>
              </div>
              <div className='flex items-center'>
                <div className='button1 w-[220px]'>
                  <p>확인하기</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
