import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import IconHamburger from 'assets/logos/icon_hamburger.svg'
import Sidebar from './Sidebar'

export default function Header() {
  const location = useLocation()

  const [isOpen, setIsOpen] = useState(false)

  const navigationList = [
    { name: '홈', path: '/' },
    { name: '서비스 소개', path: '/service' },
    { name: '콘텐츠', path: '/content' },
    { name: 'S/W 다운로드', path: '/swdownload' },
  ]
  return (
    <>
      <div
        className='bg-white border-b w-full h-[60px] fixed z-50'
        style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.20)' }}
      >
        <div className='container h-full mx-auto'>
          <div className='flex items-center justify-between h-full px-20'>
            <Link to={'/'}>
              <img src={require('assets/logos/logo-petament.png')} alt='' className='select-none' />
            </Link>
            <div className='hidden sm:flex gap-40 lg:gap-[60px] xl:gap-[100px] items-center h-full'>
              {navigationList.map((nav, index) => (
                <Link
                  key={index}
                  to={nav.path}
                  className={`h-full flex items-center text-h2-semibold
            ${location.pathname === nav.path ? 'text-[#0052A3]' : 'text-sk-default'}`}
                >
                  <p className='select-none'>{nav.name}</p>
                </Link>
              ))}
            </div>
            <div className='sm:hidden'>
              <img
                src={IconHamburger}
                alt=''
                className='w-24 h-24 cursor-pointer'
                onClick={() => setIsOpen(true)}
              />
            </div>
          </div>
        </div>
        {isOpen && <Sidebar setIsOpen={setIsOpen} />}
      </div>
      <div className='w-full' style={{ height: '60px' }}></div>
    </>
  )
}
