import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from 'views/Home'
import Service from 'views/Service'
import Content from 'views/Content'
import Dowonload from 'views/Dowonload'

import Header from 'components/Header'
import Footer from 'components/Footer'
import ScrollToTop from 'components/ScrollToTop'

function App() {
  return (
    <div className='App'>
      <Router>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='/service' element={<Service />}></Route>
          <Route path='/content' element={<Content />}></Route>
          <Route path='/swdownload' element={<Dowonload />}></Route>
        </Routes>
        <Footer />
      </Router>
    </div>
  )
}

export default App
