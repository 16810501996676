import React from 'react'

export default function Footer() {
  return (
    <div className='bg-[#F7F7F8] w-full'>
      <div className='container px-40 py-20 mx-auto'>
        <div className='flex flex-col gap-10'>
          <div>
            <img src={require('assets/logos/logo-petament.png')} alt='' />
          </div>
          <div className='w-full h-[1px] bg-[#878A93]'></div>
          <div className='flex gap-[60px] text-body1-semibold sm:text-h2-semibold text-sk-black'>
            <a
              href='https://petament-home-public.s3.ap-northeast-2.amazonaws.com/privacy_policy.pdf'
              target='_blank'
              rel='noreferrer'
            >
              개인정보처리방침
            </a>
            <a
              href='https://petament-home-public.s3.ap-northeast-2.amazonaws.com/terms.pdf'
              target='_blank'
              rel='noreferrer'
            >
              이용약관
            </a>
          </div>
          <div className='flex flex-col xl:flex-row gap-4 xl:gap-[12px]'>
            <div className='flex flex-col sm:flex-row gap-4 sm:gap-[12px]'>
              <div className='flex gap-[12px]'>
                <div className='flex gap-[8px]'>
                  <p className='text-body3-regular sm:text-body1-regular text-sk-black'>상호</p>
                  <p className='text-body3-regular sm:text-body1-regular text-neutral-60'>
                    페타멘트
                  </p>
                </div>
                <div className='flex gap-[8px]'>
                  <p className='text-body3-regular sm:text-body1-regular text-sk-black'>대표자명</p>
                  <p className='text-body3-regular sm:text-body1-regular text-neutral-60'>신재성</p>
                </div>
              </div>

              <div className='flex gap-[8px]'>
                <p className='text-body3-regular sm:text-body1-regular text-sk-black'>
                  사업자등록번호
                </p>
                <p className='text-body3-regular sm:text-body1-regular text-neutral-60'>
                  517-44-01175
                </p>
              </div>
            </div>
            <div className='flex flex-col lg:flex-row gap-4 lg:gap-[12px]'>
              <div className='flex gap-[8px]'>
                <p className='text-body3-regular sm:text-body1-regular text-sk-black'>이메일</p>
                <p className='text-body3-regular sm:text-body1-regular text-neutral-60'>
                  petament5847@gmail.com
                </p>
              </div>
              <div className='flex gap-[8px]'>
                <p className='text-body3-regular sm:text-body1-regular text-sk-black shrink-0'>
                  주소
                </p>
                <p className='text-body3-regular sm:text-body1-regular text-neutral-60'>
                  서울특별시 광진구 아차산로 549, 1012동 1604호(광장동, 현대파크빌)
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
