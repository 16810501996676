import React from 'react'
import { Link } from 'react-router-dom'

import Service1 from 'assets/icons/service-icon1.svg'
import Service2 from 'assets/icons/service-icon2.svg'
import Service3 from 'assets/icons/service-icon3.svg'

export default function Service() {
  return (
    <div className='wrapper'>
      {/* 1 */}
      <div className='relative'>
        <img src={require('assets/images/service-background.png')} alt='' className='w-full' />

        <div className='container mx-auto'>
          <div className='absolute flex flex-col px-20 -translate-y-1/2 top-1/2 gap-30 md:px-80'>
            <p className='text-body2-semibold sm:text-h2-semibold md:text-h1-semibold lg:text-title3-bold xl:text-title2-bold text-sk-primary-white'>
              페타멘트는
              <br />
              3D 프린팅 산업의 친환경적인 가치를 전달하고
              <br />
              누구나 쉽게 3D 프린터를 사용할 수 있게 하는
              <br />
              [기기-소프트웨어 종합 3D 프린팅 솔루션] 입니다.
            </p>
            <p className='hidden lg:block text-body2-long-medium xl:text-body1-long-medium text-sk-primary-white'>
              페타멘트는 페트병을 재활용할 수 있는 친환경 3D 프린터 기기와 자체 구축된 전용 모델링
              <br />
              S/W, &lt;에듀블럭&gt; 함께 지속가능한 3D 프린팅 환경을 조성하고 있으며 3D 프린터 활용
              <br />
              교육이 이루어지는 공공·교육기관에서부터 개별적인 메이커스 활동을 진행하는 개인까지
              <br />
              다양한 교육 콘텐츠와 결합하여 환경적이고 손쉬운 3D 프린팅 과정을 지원합니다.
            </p>
          </div>
        </div>
      </div>

      {/* 2 */}
      <div className='bg-sk-white'>
        <div className='px-20 py-[60px] flex flex-col gap-20 sm:gap-40 justify-center items-center'>
          <p className='text-title3-bold sm:text-title2-bold lg:text-title1-bold text-sk-primary-blue'>
            Product & Service
          </p>
          <p className='text-body3-medium sm:text-body1-medium lg:text-h2-medium xl:text-h1-medium text-[#444444] text-center sm:px-0'>
            페타멘트는 지속가능한 3D프린팅 환경을 조성하고,
            <br className='hidden sm:block xl:hidden' />
            3D 프린터를 처음 접하더라도 쉽게 사용할 수 있는 제품과 서비스를 제공하고 있습니다.&nbsp;
            <br className='hidden sm:block ' />
            페트병 Eco-cycle 기반 친환경 3D 프린터에서부터 전용 3D모델링 소프트웨어를 지원하고
            있으며&nbsp;
            <br className='hidden sm:block ' />
            페타멘트 제품 및 서비스를 활용한 ESG 가치기반 3D 프린터 활용 교육을 진행하고 있습니다.
          </p>
        </div>
      </div>

      {/* 3 */}
      <div className='flex justify-center service-background'>
        {/* 3-1 */}
        <div className='w-[1440px] px-20 py-20 lg:py-[60px] flex flex-col lg:flex-row justify-between lg:items-center gap-20 lg:gap-[60px]'>
          <div className='flex justify-center '>
            <img src={require('assets/images/service-image1.png')} alt='' className='' />
          </div>

          <div className='flex flex-col gap-30'>
            <div className='hidden lg:block '>
              <img src={Service1} alt='' className='w-[60px] h-[60px]' />
            </div>

            <div className='max-w-[700px] mx-auto w-full flex flex-col items-end justify-center gap-20 lg:items-start max-lg:text-start lg:gap-40'>
              <p className='text-title3-bold lg:text-title2-bold xl:text-title1-bold text-sk-primary-blue'>
                01. 친환경 3D 프린터
              </p>
              <p className='max-w-[470px] lg:max-w-none text-end lg:text-start text-body1-medium lg:text-h2-medium xl:text-h1-medium text-sk-black'>
                페타멘트는 3D 프린팅의 친환경적인 가치 창출을 위해서 <br />
                폐페트병을 이용하여 필라멘트를 만드는 방식을 이용합니다.&nbsp;
                <br className='hidden lg:block' />
                이를 통해 언제 어디서든 필라멘트를 만들어 3D 프린팅을 할 수 있게 합니다.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* 3-2 */}
      <div className='flex justify-center bg-sk-white'>
        {/* Responsive - lg 이상 */}
        <div className='w-[1440px] px-20 py-20 lg:py-[60px] hidden lg:flex flex-col lg:flex-row justify-between lg:items-center gap-20 lg:gap-[60px]'>
          <div className='flex flex-col gap-30'>
            <div className='hidden lg:block '>
              <img src={Service2} alt='' className='w-[60px] h-[60px]' />
            </div>

            <div className='flex flex-col items-end justify-center gap-20 lg:items-start max-xl:w-full max-lg:text-start lg:gap-40'>
              <p className='text-title3-bold lg:text-title2-bold xl:text-title1-bold text-sk-primary-blue'>
                02. 3D 모델링 소프트웨어
              </p>
              <p className='max-w-[350px] lg:max-w-none text-end lg:text-start text-body1-medium lg:text-h2-medium xl:text-h1-medium text-sk-black'>
                페타멘트는 3D 프린터를 처음 사용하더라도 <br />
                쉽게 프린팅할 수 있게 소프트웨어와 교육을 통해&nbsp;
                <br className='hidden lg:block' />
                3D모델링부터 결과물 사출까지 사용자의 전 주기를 지원합니다.
              </p>
              <div className='flex flex-row items-center gap-16'>
                <Link to={'/swdownload'} className='button1 w-[220px]'>
                  <p>S/W 확인하기</p>
                </Link>
              </div>
            </div>
          </div>
          <div className='flex justify-center'>
            <img src={require('assets/images/service-image2.png')} alt='' className='' />
          </div>
        </div>
        {/* Responsive - lg 이하 */}
        <div className='w-[1440px] px-20 py-20 lg:py-[60px] lg:hidden flex flex-col lg:flex-row justify-center lg:items-center gap-20 lg:gap-[60px]'>
          <div className='flex flex-col gap-30'>
            <div className='flex justify-center'>
              <img src={require('assets/images/service-image2.png')} alt='' className='' />
            </div>
            <div className='hidden lg:block '>
              <img src={Service2} alt='' className='w-[60px] h-[60px]' />
            </div>

            <div className='max-w-[700px] mx-auto w-full flex flex-col justify-center gap-20 max-xl:w-full lg:gap-40'>
              <p className='text-title3-bold lg:text-title2-bold xl:text-title1-bold text-sk-primary-blue'>
                02. 3D 모델링 소프트웨어
              </p>
              <p className='max-w-[400px] text-body1-medium lg:text-h2-medium xl:text-h1-medium text-sk-black'>
                페타멘트는 3D 프린터를 처음 사용하더라도 <br />
                쉽게 프린팅할 수 있게 소프트웨어와 교육을 통해&nbsp;
                <br className='hidden lg:block' />
                3D모델링부터 결과물 사출까지 사용자의 전 주기를 지원합니다.
              </p>
              <div className='flex flex-row items-center gap-16'>
                <Link to={'/swdownload'} className='button1 w-[220px]'>
                  <p>S/W 확인하기</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 3-3 */}
      <div className='flex justify-center bg-sk-white'>
        <div className='w-[1440px] px-20 py-20 lg:py-[60px] flex flex-col lg:flex-row justify-between lg:items-center gap-20 lg:gap-[60px]'>
          <div className='flex justify-center'>
            <img src={require('assets/images/service-image3.png')} alt='' className='' />
          </div>

          <div className='flex flex-col gap-30'>
            <div className='hidden lg:block '>
              <img src={Service3} alt='' className='w-[60px] h-[60px]' />
            </div>

            <div className='max-w-[700px] mx-auto w-full flex flex-col items-end justify-center gap-20 lg:items-start max-xl:w-full max-lg:text-start lg:gap-40'>
              <p className='text-title3-bold lg:text-title2-bold xl:text-title1-bold text-sk-primary-blue'>
                03. 3D프린팅 교육 프로그램
              </p>
              <p className='max-w-[350px] lg:max-w-none text-end lg:text-start text-body1-medium lg:text-h2-medium xl:text-h1-medium text-sk-black'>
                누구나 쉽게 3D 프린터를 배울 수 있고 <br />
                페트병 재활용을 통해 친환경적인 가치를 창출할 수 있는&nbsp;
                <br className='hidden lg:block' />
                전문적인 3D프린터 교육을 실시합니다.
              </p>
              <div className='flex flex-row items-center gap-16'>
                <Link to={'/content'} className='button1 w-[220px]'>
                  <p>교육 콘텐츠 확인하기</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
