import React from 'react'
import Close from 'assets/icons/close.svg'
import { Link } from 'react-router-dom'

export default function Sidebar({ setIsOpen }) {
  return (
    <div className='fixed top-0 right-0 w-full h-full bg-[#FFF] flex flex-col px-20 py-[20px]'>
      <div className='flex justify-end mb-[47px]'>
        <img
          src={Close}
          alt=''
          className='w-24 h-24 cursor-pointer'
          onClick={() => setIsOpen(false)}
        />
      </div>
      <div className='flex flex-col items-center justify-center gap-[54px]'>
        <Link to={'/'} onClick={() => setIsOpen(false)}>
          <img src={require('assets/logos/logo-petament.png')} alt='' className='h-[40px]' />
        </Link>

        <div className='flex flex-col gap-[50px] text-h1-semibold text-sk-default  justify-center items-center'>
          <Link
            to={'/'}
            className='cursor-pointer select-none hover:text-sk-primary'
            onClick={() => setIsOpen(false)}
          >
            홈
          </Link>
          <Link
            to={'/service'}
            className='cursor-pointer select-none hover:text-sk-primary'
            onClick={() => setIsOpen(false)}
          >
            서비스 소개
          </Link>
          <Link
            to={'/content'}
            className='cursor-pointer select-none hover:text-sk-primary'
            onClick={() => setIsOpen(false)}
          >
            콘텐츠
          </Link>
          <Link
            to={'/swdownload'}
            className='cursor-pointer select-none hover:text-sk-primary'
            onClick={() => setIsOpen(false)}
          >
            S/W 다운로드
          </Link>
        </div>
      </div>
    </div>
  )
}
