import React, { useRef, useState, useCallback } from 'react'

const postingList = [
  {
    img: require('assets/images/content-posting1.png'),
    title: '친환경 3D 프린팅의 길잡이, 페타멘트를 소개합니다',
    detail:
      '최근 전 세계적으로 플라스틱 폐기물 문제가 큰 이슈로 대두되고 있습니다. 특히 일회용 페트병의 사용이 늘어나면서 그 처리가 중요한 과제가 되고 있죠. 오늘은 이러한 문제를 해결하고 첨단 산업의 친환경적인 가치를 실현하는 친환경 3D 프린팅 혁신기업, 페타멘트에 대해서 자세히 알아보도록 하겠습니다.',
    link: 'https://petament.tistory.com/1',
  },
  {
    img: require('assets/images/content-posting2.png'),
    title: '2024 3D 프린팅 산업동향',
    detail:
      '3D 프린팅 산업은 매해를 거듭하며 놀라운 성장을 이어가고 있습니다. 2023년의 디지털 제조, 3D 하드웨어 및 소프트웨어 발전 등 다양한 혁신과 발전을 거듭해온 결과 점차 다양한 산업에서 활용도가 증가하고 있는데요, 오늘은 2024년 3D 프린팅 산업의 주요 이슈에 대해서 다뤄보고자 합니다.',
    link: 'https://petament.tistory.com/2',
  },
  {
    img: require('assets/images/content-posting3.png'),
    title: '3D 프린팅 사용자를 위한 CA 소프트웨어',
    detail:
      '3D 프린팅이 점점 대중화되면서 많은 이들이 3D 모델링에 대한 관심을 가지기 시작했습니다. 하지만 막상 시작하려면 복잡한 소프트웨어나 전문적인 기술 때문에 쉽게 다가가기 어려운 것이 현실입니다. 만약 여러분이 3D 프린팅을 시작한다면 어떤 소프트웨어를 사용해야할 것인지 많은 고민을 하고 있을 것입니다.',
    link: 'https://petament.tistory.com/3',
  },
  {
    img: require('assets/images/content-posting4.png'),
    title: '무료 모델링 템플릿 사이트 TOP 5',
    detail:
      '3D 프린터를 사용하다 보면 다양한 모델링 템플릿이 필요할 때가 많습니다. 특히 직접 모델링하기 어려운 복잡한 구조나 독창적인 디자인을 구현하기 위해서는 고퀄리티 템플릿이 필수적인데요. 오늘은 무료로 3D 모델링 템플릿을 제공하는 상위 5개 사이트를 소개해드리고자 합니다.',
    link: 'https://petament.tistory.com/4',
  },
]

export default function Content() {
  // drag 로직
  const sliderRef = useRef(null)
  const [mouseDown, setMouseDown] = useState(false)
  const [startX, setStartX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)

  const move = useCallback(
    (e) => {
      if (!mouseDown) return
      const slider = sliderRef.current
      const x = e.pageX - slider.offsetLeft
      const walk = x - startX
      slider.scrollLeft = scrollLeft - walk
    },
    [mouseDown, startX, scrollLeft]
  )

  const startDragging = useCallback((e) => {
    setMouseDown(true)
    const slider = sliderRef.current
    setStartX(e.pageX - slider.offsetLeft)
    setScrollLeft(slider.scrollLeft)
  }, [])

  const stopDragging = useCallback(() => {
    setMouseDown(false)
  }, [])

  // 블로그 새탭 오픈 로직
  const onClickPosting = (url) => {
    window.open(url, '_blank', 'noopener, noreferrer')
  }

  return (
    <div className='wrapper'>
      {/* 1 */}
      <div className='relative'>
        <div>
          <img src={require('assets/images/content-background.png')} alt='' className='w-full' />
        </div>
        <div className='absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2'>
          <p className='text-center max-sm:text-[20px] text-title3-bold md:text-title2-bold lg:text-title1-bold xl:text-display2-bold 2xl:text-display1-bold text-sk-white'>
            페타멘트의 다양한
            <br />
            3D 프린팅 콘텐츠를 <br className='block sm:hidden' />
            확인해보세요!
          </p>
        </div>
      </div>

      {/* 2 */}
      <div className='bg-sk-white'>
        <div className='container mx-auto px-[20px] py-[60px] flex flex-col items-center gap-40'>
          <p className='text-title3-bold sm:text-title2-bold lg:text-title1-bold text-sk-primary-blue'>
            페타먼트 소개영상
          </p>
          <div className='w-[350px] h-[250px] md:w-[700px] md:h-[450px] xl:w-[1000px] xl:h-[600px]'>
            <iframe
              className='w-full h-full'
              src='https://www.youtube.com/embed/xj33H5dnG1k?si=seGLqtGEmUHjprY1'
              title='www.youtube.com/@Petament'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              referrerPolicy='strict-origin-when-cross-origin'
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>

      {/* 3 */}
      <div className='content-posting_background'>
        <div className='container mx-auto'>
          <div className='py-[60px] flex flex-col justify-center items-center gap-20'>
            <div className='flex flex-col gap-20 text-center'>
              <p className='text-title3-bold sm:text-title2-bold lg:text-title1-bold text-sk-primary-blue'>
                Posting
              </p>
              <p className='text-body1-medium sm:text-h2-medium lg:text-h1-medium text-sk-default'>
                3D 프린팅 활동을 지원하는 페타멘트 온라인 포스팅 콘텐츠
              </p>
            </div>

            <div className='px-[20px] w-full h-[340px] mb-[100px]'>
              <div
                ref={sliderRef}
                className='flex gap-40 px-20 py-40 overflow-x-scroll scrollbar-hide'
                onMouseDown={startDragging}
                onMouseMove={move}
                onMouseUp={stopDragging}
                onMouseLeave={stopDragging}
              >
                {postingList.map((post, index) => (
                  <div
                    key={index}
                    className='min-w-[320px] min-h-[320px] pb-12 bg-sk-primary-white content-posting_box rounded-12 cursor-pointer'
                    onClick={() => onClickPosting(post.link)}
                  >
                    <div className='flex flex-col gap-16'>
                      <div
                        className='bg-center bg-cover h-[200px] rounded-10'
                        style={{ backgroundImage: 'url(' + post.img + ')' }}
                      ></div>
                      <div className='px-10'>
                        <p className='text-body2-semibold text-sk-default'>{post.title}</p>
                      </div>
                      <div className='px-10 content-posting_text'>
                        <p className='leading-5 text-body3-medium text-sk-info text-ellipsis '>
                          {post.detail}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
